import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import admin from './index';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: 'index',
  },
  {
    path: '/index',
    name: 'home',
    component: HomeView,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Details.vue')
  },
  {
    path: '/weixin',
    name: 'weixin',
    component: () => import('../views/Weixin.vue')
  },
  {
    path: '/404',
    name: 'Error',
    component: () => import('../views/Error.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/Index.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/admin/Login.vue'),
        meta: {
            title: '登录',
        },
      },
      {
          path: '',
          redirect: 'index',
      },
      {
          path: 'index',
          name: 'index',
          component: () => import('../views/admin/Home.vue'),
          meta: {
              title: '登录',
              isLogin: true,
          },
      },
    ]
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/History.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue'),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: '/searchres',
    name: 'search',
    component: () => import('../views/SearchRes.vue')
  },
  {
    path: '/player',
    name: 'Player',
    component: () => import('../views/Player.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '*', // 页面不存在的情况下会跳到404页面
    redirect: '/404',
    name: 'notFound',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 判断是否是微信浏览器
function isWeiXin() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('micromessenger') !== -1;
}

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token');

  if (to.path == '/player' && isWeiXin()) {
    next('/weixin?id=' + to.query.id)
  } else if (to.meta.isLogin && token != 'wuyifan') {
    next('/admin/login')
  } else {
    next();
  }
})


export default router
